html,
body,
#root,
.router-container {
  height: 100%;
}
body {
  background-color: #696969;
  color: #ffffff;
  font-family: "Ubuntu", sans-serif;
}

.nav-container {
  width: 100%;
  height: 110px;
  border: 10px solid #ffffff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  .navNarrow {
    display: none;
    flex-direction: row;
    flex: 0 0 20px;
    max-width: 960px;

    top: 0;
    left: 0;
    height: 100%;
    @media (min-width: 320px) and (max-width: 1060px) {
      position: absolute;
      display: inline-block;
      navbar {
        padding: none;
      }
      .navbar-toggler {
        border: none;
        padding: none;
        font-size: 1.75rem;

        &-icon.open {
          margin-top: 32.5px;
          margin-left: 5px;
        }
      }
      .navbar-toggler,
      .nav-item {
        text-align: left;
      }
      .nav-item {
        margin: 0 20px;
        flex: 0 0 12.5%;
      }
      .navbar-collapse {
        background-color: #ff6d00;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0;
        left: 0;
        width: 95%;
      }
      .navbar-collapse.collapse.show {
        transition: 0.5s ease-in-out;
        transform: rotate(0deg);
        z-index: 99;
        height: 100vh;
        .navbar-nav {
          height: 100vh;
        }
      }
    }
  }
  .navWide {
    display: flex;
    flex: 0 0 100%;
    align-items: center;
    justify-content: space-around;
    max-width: 960px;
    margin: auto;
  }
  @media (max-width: 1060px) {
    .navWide {
      justify-content: center;
      flex: 0 0 60%;
    }
    .navWide .nav-item {
      display: none;
    }
    .navWide .nav-item.logo {
      display: inline-block;
    }
  }
  .nav-item {
    flex: 0 0 20%;
    text-align: center;
  }

  a {
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
    color: #ffffff;
  }
}
.logo {
  img {
    height: 76px;
  }
}
.side_wrapper {
  display: none;
  top: 0;
  left: 0;
  height: 100%;
  @media (min-width: 320px) and (max-width: 1060px) {
    display: inline-block;
    margin-top: 15px;
    nav.navbar {
      background-color: transparent !important;
    }
  }
}

.powercoin-count {
  position: absolute;
  right: 30px;
  @media (min-width: 320px) and (max-width: 1060px) {
    right: 10px;
  }
  img,
  span {
    display: block;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    @media (min-width: 320px) and (max-width: 1060px) {
      font-size: 14px;
    }
  }
  img {
    height: 56px;
    width: 56px;
    @media (min-width: 320px) and (max-width: 480px) {
      height: 56px;
      width: 56px;
    }
  }
  span {
    padding-top: 2px;
    line-height: 21px;
    @media (min-width: 320px) and (max-width: 480px) {
      padding-top: none;
    }
  }
}

.main-content {
  max-width: 960px;
  width: 100%;
  margin: auto;
  padding-top: 110px;
  text-align: center;
  @media screen and (max-width: 975px){
    padding-left: 15px;
    padding-right: 15px;
  }
  .main-title {
    margin-top: 72px;
    font-size: 36px;
    line-height: 42px;
    @media (min-width: 320px) and (max-width: 480px) {
      font-size: 24px;
      margin-top: 26px;
      br {
        display: none;
      }
    }
  }
  h3.main-title {
    @media (min-width: 320px) and (max-width: 480px) {
      font-size: 28px;
      font-weight: 400;
    }
  }
  .subtitle {
    font-weight: 400;
    font-style: italic;
    margin-top: 40px;
    @media (min-width: 320px) and (max-width: 480px) {
      font-size: 1.5rem;
      margin-top: 20px;
    }
  }
  .perpetual-name {
    font-size: 64px;
    line-height: 74px;
    display: block;
    font-weight: bold;
    text-align: center;
    @media (min-width: 320px) and (max-width: 480px) {
      font-size: 48px;
      line-height: 60px;
      margin-top: 5px;
    }
  }
  .score-container {
    font-size: 34px;
    line-height: 42px;
    font-weight: 400;
    @media (min-width: 320px) and (max-width: 480px) {
      // margin-top: -250px;
      &.account {
        margin-top: 0;
      }
    }
    &.paragraph {
      margin-top: 25px;
      width: 32%;
      @media (min-width: 320px) and (max-width: 480px) {
        width: 75%;
        font-size: 26px;
        line-height: 34px;
        margin-top: 30px;
      }
    }
    .score-inner-container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 10px;
      .powerscore-text {
        font-weight: 400;
        font-size: 64px;
        line-height: 66px;
        font-style: italic;
        color: #ff6d00;
        padding-right: 20px;
        text-align: left;
        @media (min-width: 320px) and (max-width: 480px) {
          font-size: 58px;
          margin-top: 10px;
        }
      }
      .score-amount {
        flex: 0 0 128px;
        position: relative;
        img {
          height: 128px;
        }
        .score {
          position: absolute;
          left: 45%;
          top: 55%;
          color: rgba(12, 255, 0, 0.6);
          line-height: 74px;
          font-size: 36px;
          font-style: italic;
          transform: translate(-50%, -50%);
          &::first-letter {
            font-size: 64px;
            font-weight: bold;
          }
        }
      }
    }
  }
  p {
    width: 70%;
    margin: auto;
    margin-top: 20px;
    font-weight: 300;
    font-size: 24px;
    line-height: 28px;
    @media (min-width: 320px) and (max-width: 480px) {
      font-size: 16px;
      line-height: 28px;
      width: 100%;
    }
  }
}
.btn.btn-orange, .btn-orange.btn.btn-primary {
  background-color: #ff6d00;
  border: 2px solid #ffffff;
  color: #fff;
  border-radius: 5px;
  font-size: 18px;
  line-height: 21px;
  margin-top: 30px;
  padding: 5px 40px;
  cursor: pointer;
  &:focus,
  &:hover,
  &:active {
    border: 2px solid #ffffff;
    background-color: #ff6d00;
    opacity: 0.8;
  }
  &.btn-standard {
    min-width: 200px;
  }
  &.small {
    margin-top: 10px;
    padding: 3px 30px;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .btn.btn-orange {
    margin-top: 10px;
  }
}

.multiple-btns {
  display: flex;
  justify-content: space-between;
  max-width: 540px;
  margin: auto;
  @media (min-width: 320px) and (max-width: 480px) {
    display: inline-block;
  }
}

.metamask-container {
  position: relative;
  @media screen and (max-width: 768px){
    display: none;
  }
  .error-text {
    max-width: 200px;
    bottom: -65px;
    position: absolute;
    color: #f06464;
    font-size: 14px;
    width: 100%;
    font-style: italic;
  }
}

a.bold-inline {
  font-weight: 500;
  display: inline-block;
  color: inherit;
}

.skyline {
  position: absolute;
  bottom: 30px;
  width: 100%;
  z-index: -1;
  img {
    width: 100%;
  }
  @media screen and (min-width: 1300px) {
    position: absolute;
  }
  @media (min-width: 320px) and (max-width: 768px) {
    position: relative;
    bottom: 0;
    padding-bottom: 30px;
  }
}

.quiz-nav {
  display: flex;
  flex: 0 0 100%;
  width: 100%;
  max-width: 960px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  bottom: 40px;
  justify-content: space-around;
  @media screen and (max-width: 768px){
    position: relative;
    bottom: 0;
  }
  .quiz-nav-block {
    width: 64px;
    height: 64px;
    border: 4px solid #ffffff;
    border-radius: 10px;
    position: relative;
    &.active {
      background-color: #ff6d00;
    }
    @media (min-width: 320px) and (max-width: 768px) {
      width: 48px;
      height: 48px;
    }
  }
  .quiz-nav-title {
    position: absolute;
    bottom: -35px;
    left: 50%;
    transform: translateX(-50%);
    @media (min-width: 320px) and (max-width: 768px) {
      font-size: 0.8rem;
    }
  }
}

label {
  display: block;
}
.input-container {
  position: relative;
  max-width: 300px;
  margin: auto;
}
input {
  background-color: transparent;
  border: 4px solid #ffffff;
  border-radius: 10px;
  color: #ffffff;
  height: 64px;
  font-size: 24px;
  padding-left: 10px;
  max-width: 300px;
  margin: auto;
  position: relative;
}
.wallet-container {
  max-width: 450px;
  width: 100%;
  margin: auto;
  h1 {
    margin-bottom: 40px;
  }
  input {
    font-size: 16px;
    max-width: 450px;
    width: 100%;
  }
}
.next-button {
  position: absolute;
  width: 64px;
  height: 64px;
  right: 0;
  top: 0;
  border: 4px solid #ffffff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  transition: background-color 0.25s ease-in-out;
  cursor: pointer;
  z-index: 2;
  background-color: #696969;
  &:hover {
    background-color: #ff6d00;
    opacity: 0.8;
  }
}
.quiz-container {
  @media screen and (max-width: 768px){
    align-content: space-around;
  }
  .main-content {
    padding-top: 0;
    @media screen and (max-width: 768px){
      padding-top: 140px;
    }
  }
  &.login-container {
    .main-content {
      @media screen and (max-width: 768px){
        padding-top: 0;
      }
    }
  }
  .question-container {
    display: block;
    position: relative;
    .main-title {
      margin-top: 0;
      margin-bottom: 45px;

    }
    .option-container {
      .option {
        border: 4px solid #ffffff;
        border-radius: 10px;
        height: 60px;
        max-width: 300px;
        margin: auto;
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: background-color 300ms ease;
        &:hover {
          background-color: #ff6d00;
        }
      }
    }
  }
}

.loading-container {
  text-align: left;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  .main-title {
    margin-top: 0;
  }
  .loading-text {
    flex: 0 0 33.333%;
    margin-right: 40px;
    @media screen and (max-width: 768px){
      flex: 0 0 100%;
      padding-bottom: 30px;
    }
  }
  p {
    margin: 0;
    width: 100%;
    line-height: 28px;
  }
}

.result-container,
.container,
.outer-container {
  height: 100%;
  display: flex;
  align-items: center;
  @media screen and (max-width: 768px){
    flex-wrap: wrap;
  }
}
.result-container{
  .main-content {
    padding-top: 0;
    @media screen and (max-width: 768px){
      padding-top: 60px;
    }
  }
}
.CircularProgressbar {
  flex: 0 0 200px;
  width: 200px;
  height: 200px;
  .CircularProgressbar-path {
    stroke: #ff6d00;
    stroke-width: 4px;
  }
  .CircularProgressbar-trail {
    stroke: transparent;
  }
}

.chainbolt {
  height: 92px;
}

.leaderboard-container {
  .main-content {
    padding-top: 160px;
  }

  .leaderboard-h1 {
    color: #ff6d00;
    font-size: 60px;
    line-height: 72px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-style: italic;
    background: rgba(255, 255, 255, 0.8);
    margin-bottom: 0;
    border: 4px solid #fff;
    border-radius: 10px;
    @media (min-width: 320px) and (max-width: 768px) {
      margin-top: -25px;
      font-size: 40px;
      padding: 5px;
    }
  }
  .leaderboard-row {
    display: flex;
    width: 100%;
    position: relative;
    border-radius: 10px;
    border: 4px solid #fff;
    border-left: 0;
    .leaderboard-cell {
      border-radius: 10px;
      &.ranking-container {
        border: 4px solid #ffffff;
        flex: 0 0 64px;
        height: 64px;
        width: 64px;
        position: absolute;
        z-index: 2;
        font-size: 24px;
        line-height: 28px;
        display: flex;
        flex-direction: column;
        top: -2px;
        .rank-num {
        }
        .rank-badge {
          flex: 1 0 auto;
          position: relative;
          &:after {
            position: absolute;
            width: 64px;
            height: 100%;
            content: "Rank";
            left: -4px;
            top: 3px;
            border: 4px solid #fff;
            border-radius: 10px;
            font-size: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          &.rank-1:after {
            content: "";
            background: radial-gradient(circle, #ffffff 0%, #f2db01 100%);
          }
          &.rank-2:after {
            content: "";
            background: radial-gradient(circle, #ffffff 0%, #a19788 100%);
          }
          &.rank-3:after {
            content: "";
            background: radial-gradient(circle, #ffffff 0%, #985900 100%);
          }
        }
      }
      &.leader-name {
        flex: 1 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 70px;
        height: 60px;
        .leader-span {
          flex: 0 0 300px;
          text-align: center;
          font-size: 30px;
          line-height: 35px;
          @media (min-width: 320px) and (max-width: 768px) {
            text-align: left;
            padding-left: 10px;
            font-size: 18px;
            flex: 0 0 180px;
          }
        }
        .token-amount {
          padding-right: 20px;
          flex: 0 0 140px;
          font-size: 24px;
          font-weight: 300;
          line-height: 60px;
          @media (min-width: 320px) and (max-width: 768px) {
            font-size: 14px;
            display: inline-block;
            flex-direction: column;
            flex: 0 0px 10px;
            position: relative;
          }
          img {
            height: 45px;
            width: 45px;
            margin-right: 10px;
            position: relative;
            @media (min-width: 320px) and (max-width: 768px) {
              margin-right: 0px;
              height: 35px;
              width: 35px;
              display: flex;
              top: 20px;
              position: relative;
              flex-direction: column;
              flex-wrap: wrap;
            }
          }
        }
      }
    }
    &.is-user {
      border-color: #ff6d00;
      font-weight: 500;
      .ranking-container,
      .rank-badge:after {
        border-color: #ff6d00 !important;
      }
      .leader-span {
        font-style: italic;
      }
    }
  }
}

.contact-container {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  @media screen and (min-width: 1300px){
    align-content: center;
  }
  .main-content {
    flex: 0 0 100%;
    @media screen and (max-width: 1300px){
      padding-top: 0;
    }
  }
  @media (min-width: 320px) and (max-width: 480px) {
    // display: inline-block;
  }
}

.contact-inner {
  max-width: 304px;
  margin: auto;
  padding-bottom: 100px;
  @media (min-width: 320px) and (max-width: 480px) {
    padding-bottom: 0;
    h4 {
      margin-top: 15px;
    }
  }
  label {
    text-align: left;
    @media (min-width: 320px) and (max-width: 480px) {
      display: inline-block;
      margin-top: 10px;
    }
  }
  textarea {
    width: 100%;
    background: transparent;
    border: 4px solid #fff;
    border-radius: 10px;
    min-height: 250px;
    color: #fff;
    padding: 5px;
  }
  .btn {
    margin-top: 5px;
    float: right;
    padding: 1px 10px;
    border-radius: 15px;
    border-width: 4px;
  }
  .small-cta {
    text-align: left;
    font-size: 14px;
    margin-bottom: 5px;
    a {
      color: #ff6d00;
    }
  }
  @media (min-width: 320px) and (max-width: 480px) {
    textarea {
      min-height: 150px;
      margin-bottom: 10px;
    }
  }
}

.complete-container {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 60px;
  .main-content {
    margin: unset;
    margin-left: auto;
    margin-right: auto;
    padding-top: 160px;
    text-align: left;
    max-width: 1024px;
    h1, h2 {
      font-size: 36px;
      line-height: 42px;
      font-weight: 300;
      @media screen and (max-width: 768px){
        font-size: 24px;
        line-height: 36px;
        text-align: center;
        br {
          display: none;
        }
      }
    }
    h1 {
      padding-bottom:: 30px;
      padding-top: 40px;
      font-weight: 500;
      @media screen and (max-width: 768px){
        padding-bottom: 15px;
      }
    }
  }
  .next-steps {
    h2 {
      @media screen and (max-width: 768px){
        display: none;
      }
    }
    .step {
      display: flex;
      align-items: center;
      padding-top: 20px;
      padding-bottom: 30px;
      font-size: 18px;
      line-height: 21px;
      @media screen and (max-width: 768px){
        flex-wrap: wrap;
        justify-content: center;
      }
      &.has-btn {
        align-items: flex-start;
      }
      span {
        display: block;
      }
      .step-num {
        flex: 0 0 40px;
        height: 40px;
        border-radius: 50%;
        border: 2px solid #FFF;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 15px;
        @media screen and (max-width: 768px){
          margin-bottom: 20px;
          margin-right: 0;
        }
      }
      .step-desc {
        @media screen and (max-width: 768px){
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          span {
            flex: 0 0 100%;

          }
          a {
            margin-top: 20px;
          }
        }
      }
    }
  }
}

.social-module {
  display: flex;
  @media screen and (max-width: 768px){
    flex-wrap: wrap;
  }
  .social-column {
    flex: 0 0 33%;
    @media screen and (max-width: 768px){
      flex: 0 0 100%;
      margin-bottom: 30px;
    }
    .social-inner {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      text-align: center;
    }
    h4 {
      text-align: center;
      flex: 0 0 100%;
    }
    .social-icon {
      flex: 0 0 48px;
      height: 48px;
      margin-left: 3px;
      margin-right: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(255,255,255,0.3);
      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.faq-container {
  display: flex;
  justify-content: space-between;
  padding-top: 60px;
  flex-wrap: wrap;
  h2 {
    flex: 0 0 100%;
    padding-bottom: 15px;
  }
  .faq-container-inner {
    flex: 0 0 48%;
    @media screen and (max-width: 768px){
      flex: 0 0 100%;
    }
  }
  details {
    padding-bottom: 20px;

  }
  summary, .dropdown-inner {
    padding: 30px 20px;
    border: 4px solid #FFF;
    border-radius: 10px;
    background-color: #696969;
  }
  .dropdown-inner {
    position: relative;
    top: -20px;
    z-index: -1;
  }
}

.login-container {
  justify-content: center;
  @media (min-width: 320px) and (max-width: 768px) {
    margin-top: 60px;
  }
  .login-fieldgroup {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 26px;
    label,
    input::placeholder {
      color: #ffffff;
      padding-right: 30px;
      position: absolute;
      left: 10px;
      top: 10px;
      font-size: 14px;
      font-weight: 300;
    }
  }
  a {
    color: #ff6d00;
    padding-top: 10px;
    font-weight: 500;
    display: block;
  }
  .sign-in {
    .btn.btn-orange {
      margin-top: 0px;
    }
  }
}

.register-title {
  font-size: 36px;
  margin-bottom: 12px;
  font-weight: 400;
}


.next-steps-container {
  padding-top: 60px;
  padding-bottom: 60px;
  h4 {
    font-weight: 300;
    .bold {
      font-weight: 500;
    }
  }
  .token-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 5px;

    img {
      width: 24px;
      height: 24px;
    }
  }
}

.account-container {
  .score-container {
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media screen and (max-width: 768px){
      padding-top: 0;
    }
    .score-inner-container {
      flex: 0 0 40%;
      @media screen and (max-width: 1024px){
        flex-wrap: wrap;
        flex: 0 0 100%;
      }
      .powerscore-text {
        color: #FFF;
        font-size: 76px;
        line-height: 89px;
        font-weight: 300;
        @media screen and (max-width: 768px){
          font-size: 42px;
          line-height: 48px;
          text-align: center;
          flex: 0 0 100%;
          padding-bottom: 20px;
          br {
            display: none;

          }
        }
      }
      .score-amount {
        img {
          width: 154px;
          height: 154px;
        }
      }
      &.powercoin-account-container {
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 1024px){
          justify-content: center;
          margin-top: 40px;
          padding-top: 40px;
          border-top: 2px solid #FFFFFF;
          flex-wrap: wrap;
        }
        img {
          width: 112px;
          height: 112px;
        }
      }
      .powercoin-text {
        font-size: 50px;
        font-style: italic;
        text-align: left;
        font-weight: 300;
        line-height: 59px;
        @media screen and (max-width: 768px){
          font-size: 36px;
          line-height: 42px;
          flex: 0 0 100%;
          padding-bottom: 20px;
          br {
            display: none;

          }
        }
      }
    }
    .divider {
      flex: 0 0 2px;
      background-color: #FFFFFF;
    }
  }
}

.learn-more-container {
  display: flex;
  width: 100%;
  position: absolute;
  bottom: 30px;
  flex-wrap: wrap;
  left: 50%;
  transform: translateX(-50%);
  justify-content: center;
  @media screen and (max-width: 768px){
    position: relative;
    bottom: -50px;
    padding-bottom: 60px;
  }
  &.relative {
    position: relative;
    bottom: unset;
    left: unset;
    transform: unset;
    margin-bottom: 60px;
    margin-top: 60px;
    .learn-more-text {
      text-align: left;
      span {
        font-size: 24px;
        font-weight: 300;
        @media screen and (max-width: 768px){
          font-size: 16px;
          line-height: 21px;
          font-weight: 400;
          display: block;
          text-align: center;
        }
      }
    }
  }
  .learn-more-text {
    flex: 0 0 100%;
    padding-bottom: 30px;
    text-align: center;
  }
  .learn-more-icon {
    border: 4px solid #fff;
    border-radius: 10px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    padding: 10px;
    @media screen and (max-width: 768px){
      margin-bottom: 15px;
    }
    &.large {
      flex: 0 0 200px;
    }
  }
}

.hidden {
  display: none;
}
